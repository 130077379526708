<template>
	<TempEmailForm @submit="create" @cancel="cancel" />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { CREATE_TEMP_EMAIL, GET_EMAIL_LAST } from "../store/action-types"
import { RESET_EMAIL_FILTERS } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("email")
export default {
	name: "CreateTempEmail",
	components: {
		TempEmailForm: () => import("../components/TempEmailForm"),
	},
	created() {
		this.getEmailTemplateLast()
	},
	beforeDestroy() {
		this.RESET_EMAIL_FILTERS()
	},
	methods: {
		...mapActions({ CREATE_TEMP_EMAIL, GET_EMAIL_LAST }),
		...mapMutations({ RESET_EMAIL_FILTERS }),
		async create(params) {
			await this.CREATE_TEMP_EMAIL(params)
		},
		cancel() {
			this.$router.push({ name: "EmailManagement" })
		},
		async getEmailTemplateLast() {
			await this.GET_EMAIL_LAST()
		},
	},
}
</script>

<style></style>
